import {$axios_api} from '@/services/http-common.js';


export default {
    AuthRequest({ commit, dispatch }) {
        commit('AuthRequest');
        return $axios_api.get('userinfo')
            .then(response => {
                const user = { ...response.data };
                $axios_api.defaults.headers.common['X-Username'] = user['username'];
                dispatch('getOrganisations').then(() => {
                    commit('AuthSuccess', user);
                })
                return response;
            })
            .catch((error) => {
                commit('AuthError', error);
                console.log("There was an error logging in on api : " + error);
                return error;
            });
    },
    getOrganisations({ commit }) {
        // const axios = this._vm.prototype.$axios_api;
        return $axios_api.get('orgs')
            .then(response => {
                commit('setOrganisations', response.data['orgs']);
                return response.data;
            }).catch((error) => {
                commit('setOrganisations', null);
                console.log("There was an error while getting Organisations : " + error);
            });
    },
    setActiveBtn({ commit }, btnId) {
        console.log("ACTIVE BUTTON SET!!" + btnId);
        commit('setActiveBtn', btnId);
    }
};
