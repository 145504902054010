export default {
    isAuthenticated: state => !!state.authenticated,
    authStatus: state => state.status,
    StateUser: state => state.user,
    StateActiveOrganisation: state => state.user.active_org,
    StateOrganisations: state => state.user.organisations,
    StateApiUrl: state => state.apiUrl,
    StateBaseUrl: state => state.baseUrl,
    StateIsSuperAdmin: state => state.isSuperAdmin,
    StateActiveBtn: state => state.activeBtn
};
