import defaultAuthState from "@/store/modules/auth/defaultAuthState";
import store from '@/store/index';

export default {
    AuthRequest(state) {
        state.status = "loading";
        let tempBaseUrl;
        if (process.env.VUE_API_URL) tempBaseUrl = process.env.VUE_API_URL;
        else tempBaseUrl = window.VUE_API_URL;
        state.baseUrl = tempBaseUrl;
        state.apiUrl = tempBaseUrl + '/api/1.0';
    },
    AuthSuccess(state, user){
       state.status = "success";
       state.authenticated = true;
       if(user.username) {
           state.user.username = user.username;
           state.user.ory_id = user.ory_id;
           state.user.email = user.email;
           state.user.expires_at = user.expires_at;
          if ([
              'martin.van.diest@intemo.com',
              'martin.van.diest@gmail.com',
              'harm.verhagen@intemo.com',
              'harm@symeon.nl'
          ].includes(user.email)) {
              state.isSuperAdmin = true;
          }
       }
       if(state.user.organisations.length > 0) {
           const last = state.lastKnownOrg;
           const valid = state.user.organisations.some((elem) => elem.id === last.organisation);
           if (last.username === user.username && valid) {
               state.user.active_org = last.organisation;
           } else {
               state.user.active_org = state.user.organisations[0].id;
           }
           state.lastKnownOrg  = {
               username: state.user.username,
               organisation: state.user.active_org
           };
       } else {
           // state.authenticated = false;
           store.commit('not/errorMsg', "User has no organisation", {root: true});
       }
    },
    AuthError(state, error) {
        if (error.response.status === 401){
            state.status = error.response.data.message;
        } else {
            state.status = "error";
        }
    },
    AuthReset(state){
        Object.assign(state, defaultAuthState());
    },
    AuthLogout() {
        store.commit('ctz/clearCityzenzState', null, { root: true });
        store.commit('sched/clearScheduleState', null, { root: true });
        store.commit('not/clearMessage', null, { root: true });
        store.commit('auth/AuthReset');
    },
    setOrganisations(state, organisations){
        state.user.organisations = organisations;
    },
    setActiveOrganisation(state, org) {
        const user = {
            username: state.user.username,
            organisation: org
        }
        state.lastKnownOrg  = user;
        state.user.active_org = org;
    },
    setApiUrl(state, url){
        state.apiUrl = url;
    },
    setBaseUrl(state, url){
        state.baseUrl = url;
        state.apiUrl = url + '/api/1.0';
    },
    setActiveBtn(state, btnId) {
        state.activeBtn = btnId;
    }
};
