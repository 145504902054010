export default {
    getEvents: state => state.events,
    getEventsRange: state => state.eventsRange,
    getWeekInfo: state => state.weekinfo,
    getCurrent: state => state.current,
    getSchedules: state => state.schedules,
    getSingleSchedule: state => state.singleSchedule,
    getHighestScheduleId: state => state.highestScheduleId,
    getError: state => state.error,
    getQueryData: state => state.queryData,
    getLastAdded: state => state.lastAdded,
    getGpsLocation: state => state.gpsLocation,
    getSunCalc: state => state.sunCalc,
    nodesWithSchedule: state => state.nodesWithSchedule
};
