import {
    getNodeScheduleList,
    getNodesMetaList,
    getNodesOnlineList,
    getScheduleIds,
    getSchema,
    serializeJson
} from "@/services/apiHelpers";
import Vue from "vue";
import {$axios_api} from "@/services/http-common";


export default {
    addEvent({ commit }, event) {
        commit('addEvent', event);
    },
    addEvents({ commit }, events) {
        commit('addEvents', events);
    },
    removeEvent({ commit }, date) {
        commit('removeEvent', date);
    },
    changeWeekInfo({ commit }, info) {
        commit('changeWeekInfo', info);
    },
    setEventsRange({ commit }, rangeStart, rangeEnd) {
        commit('setEventsRange', rangeStart, rangeEnd);
    },
    setCurrent({ commit }, payload) {
        commit('setCurrent', payload);
    },
    /**
     * @function Schedules
     * @description gets list of schedules. The function called gets a mode=2 by default. Which means that only the
     *              active schedules are displayed.
     *
     * @param commit
     * @param rootGetters
     * @returns {Promise<void>}
     * @constructor
     */
    async Schedules({ commit, rootGetters }){
        // let scheduleIds;
        let scheduleObjects = [];
        let highestId = 0;
        let organisation = rootGetters["auth/StateActiveOrganisation"];

        // get the schedule IDs for active schedules
        // get the schedule IDs for published schedules
        // make new Schedules list with updated information
        let [scheduleIds, activeIds, publishedIds] = await Promise.all([
            getScheduleIds(organisation, 0),
            getScheduleIds(organisation, 2),
            getScheduleIds(organisation, 1)]);
        activeIds = activeIds.map((el) => el.uid);
        publishedIds = publishedIds.map((el) => el.uid);

        for (let sched of scheduleIds) {
            const versions = scheduleIds.map((el) => el.id === sched.id ? el.version : null).filter((x) => x !== null);
            const highest = Math.max(...versions);
            scheduleObjects.push({
                id: sched.id,
                uid: sched.uid,
                name: sched.name,
                version: sched.version,
                published: publishedIds.includes(sched.uid),
                active: activeIds.includes(sched.uid),
                latest: highest
            });
            if (sched.id > highestId) {
                highestId = sched.id;
            }
        }
        commit('setSchedules', scheduleObjects);
        commit('setHighestScheduleId', { highest: highestId});
    },
    /**
     * @function searchSchedule
     * @description This searches for a schedule with:
     *                  - a certain schedule id and version.
     *                  - a schedule uid
     *              if this schedule id is not found, an error message will be generated. This error message is put in the
     *              state 'error' and can be accessed. Don't forget to 'resetError' after it has been accessed.
     *              The search function cleares the error on start of the function.
     *
     * @param commit           |
     * @param state            | for Vuex
     * @param dispatch         | store
     * @param rootGetters      |
     * @param {object} data     { schedule_id: xx, schedule_version: xx } | { schedule_uid: xx }
     */
    async searchSchedule({ state, commit, dispatch, rootGetters }, data) {

        let dataObject ={};
        if(Object.keys(data).includes('schedule_uid')) {
            dataObject['schedule_uid'] = data['schedule_uid'];
        } else if(Object.keys(data).includes('schedule_id') && Object.keys(data).includes('schedule_version')){
            dataObject['schedule_id'] = data['schedule_id'];
            dataObject['schedule_version'] = data['schedule_version'];
        }

        const singleSchedule = await getSchema(rootGetters["auth/StateActiveOrganisation"], dataObject);
        if(singleSchedule.status) {
            commit('setError', singleSchedule['errorMsg']);
            dispatch('not/errorMsg', singleSchedule['errorMsg'], {root: true});
        } else {
            commit('searchSchedule', { singleSchedule });
        }

    },
    searchHighestScheduleId({ commit }) {
        commit('setHighestScheduleId');
    },
    /**
     * Save the current schedule to the store.
     * If it is a new one. A temporary schedule will be added. To be overwritten when the data
     * has been read from the API.
     *
     * @param commit
     * @param data = {schedule_uid: the UID, schedule: the actual schedule}
     */
    saveSchedule({commit}, data) {
        commit('saveSchedule', {schedule_uid: data.schedule_uid, schedule: data.schedule });
    },
    /**
     * Sends the schedule to the API. The mode will decide if it becomes a new schedule or a new version.
     * pass the JSON object as payload
     * This must be the serialized Json object !!
     * @param commit
     * @param dispatch
     * @param rootGetters
     * @param payload = {
     *                     serializedScheduleObject : object
     *                     mode: mode  ('newVersion' or 'newSchedule' or 'updateSchedule')
     *                   }
     */
    async sendScheduleToApi({commit, dispatch, rootGetters}, payload) {
        let url;
        let body = payload.serializedScheduleObject;
        const organisation = rootGetters["auth/StateActiveOrganisation"];
        if (payload.mode === 'newSchedule') {
            url = `orgs/${organisation}/schedules`;
        } else if (payload.mode === 'newVersion'){
            url = `orgs/${organisation}/schedules/${payload.schedule_id}/versions`;
        } else if (payload.mode === 'updateName') {
            url = `orgs/${organisation}/schedules/${payload.schedule_id}/versions/${payload.version}`;
            body = {"name": payload.name};
        } else {
            // updateSchedule
            url = `orgs/${organisation}/schedules/${payload.schedule_id}/versions/${payload.version}`;
        }
        await $axios_api.post(url,  body, {
            headers: {
                'Content-Type': 'application/json'
            }
        } )
        .then( response => {
            commit('lastAdded', {id: response.data.id, uid: response.data.uid, version: response.data.version});
            return response.data;
        }).catch((error) => {
            dispatch('not/errorMsg', `Send schedule to API : ${error}`,{root: true});
            console.log("SEND SCHEDULE TO API: There was an error : " + error);
        });

        dispatch('Schedules');
    },
    resetError({commit}) {
        commit('resetError');
    },
    /**
     * This sends a schedule to one or more nodes.
     * nodesList = {
     *     listOfNodes: nodes (1 or more in a string),
     *     schedule_uid: uid of the schedule
     *     test: true/false
     * }
     * if test is true. the schedule is send in test-mode. (not actually send to the node. only to API)
     * @param commit
     * @param dispatch
     * @param nodesList array of strings. or single string.
     * @returns {Promise<void>}
     */
    async sendScheduleToNodes({ commit, dispatch }, nodesList) {
        const dataObj = {
            "uid": nodesList.schedule_uid
        }
        await $axios_api.post(`nodes/schedule/${nodesList['listOfNodes']}`, dataObj)
            .then((response) => {
                let message;
                const resp = response.data;
                if (resp.status === 'success'){
                    message = `Schedule was successfully sent to node with id ${resp.id}.`
                } else {
                    message = resp;
                }
                dispatch('not/successMsg', message, {root: true});
            })
            .catch((error) => {
                dispatch('not/errorMsg', error, {root: true});
            })
    },
    async sendScheduleToZones({ commit, dispatch, rootGetters }, payload){
        const dataObj = {
            "uid": payload.uid
        };
        const organisation = rootGetters["auth/StateActiveOrganisation"];
        const zones = payload.zonesList;
        let promises = [];
        zones.forEach((zone) => {
            promises.push($axios_api.post(`orgs/${organisation}/zones/${zone}/schedule`, dataObj))
        })

        let results = [];
        let resultLists = [];
        // then resolve all the promises at once
        await Promise.all(promises)
            .then((lists) => {
                // and put them in the lists
                results.push(lists);
                return lists;
            }).then((lists) => {
                resultLists = [...lists];
            });
        let results2 = [];
        const list2 = resultLists.map((li, index) => ({
            zoneId: payload.zonesList[index],
            data: li.data
        }));
        // create rapportage list
        // objects with zone, station id and status
        list2.forEach((list) => {
            if(list.data.length > 0) {
                list.data.forEach((dataObj) => {
                    results2.push({
                        zoneId: list.zoneId,
                        id: dataObj.id,
                        status: dataObj.status
                    });
                });
            } else {
                results2.push({
                    zoneId: list.zoneId,
                    id: -1,
                    status: 'No stations in zone'
                });
            }
        });
        dispatch('not/successMsg', results2, {root: true});
    },
    setQueryData({commit}, queryData) {
        commit('setQueryData', queryData);
    },
    getPublishedSchedules({dispatch, commit, rootGetters}) {
        dispatch('schedule/Schedules', {type: 3}, {root: true});
    },
    setGpsLocation({commit}, location) {
        commit('setGpsLocation', location);
    },
    setSunCalc({commit}, date, location) {
        commit('setSunCalc', date, location);
    },
    getNodesWithSchedule({commit}, scheduleObj){
        // scheduleObj can be {id: x, version: x} or {uid: x}
        // /api/1.0/schedules/uid/(int: schedule_uid)/nodes
        // /api/1.0/schedules/(int:schedule_id)/versions/(int:version_id)/nodes
        let url;
        if (Object.keys(scheduleObj).length === 2){
            url = `${scheduleObj.id}/versions/${scheduleObj.version}`;
        } else {
            url = `uid/${scheduleObj.uid}`;
        }
        $axios_api.get(`schedules/${url}/nodes`)
        .then((response) => {
            const resp = response.data;
            if (resp) {
                const scheduleWithNodes = {
                    schedule: {
                        uid: scheduleObj.uid ? scheduleObj.uid : -1,
                        id: scheduleObj.id ? scheduleObj.id : -1,
                        version: scheduleObj.version ? scheduleObj.version : -1
                    },
                    nodes: [...resp['nodes'].map((n) => parseInt(n.substr(15)))]
                }
                commit('getNodesWithSchedule', scheduleWithNodes);
            } else return response;
        })
        .catch((error) => {
           console.log('There was an error getNodesWithSchedules : ', error);
        })
    }
};
